import React from 'react'
import { Link } from 'gatsby'
import Seo from '../components/seo/seo';
//import Layout from '../layout/layout'

const NotFoundPage = () => (
//  <Layout>
<>
	<Seo title="404 Error - anonimoconiglio.com" description="Pagina di errore 404. Mi scuso per il disagio, per favore segnalami l'errore cosi posso indagare e risolverlo" />

	<div style={{maxWidth: '48rem', margin: '0 auto'}}>
    <h1>OH NO!! - (404)</h1>
    <p>Se sei qui significa che qualcosa è andato storto.</p>
		<Link to="/">Torna sulla Home</Link>
	</div>
</>
//  </Layout>
)

export default NotFoundPage
